import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import FacebookLogo from "../assets/facebook.png";
import TwitterLogo from "../assets/twitter.png";
//import LogoWhite from "../assets/logo-white.png";
import "./Footer.css";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

function Copyright() {
  return (
    <p
      style={{ fontSize: "12px", padding: "5px", color: "#999", margin: "0px" }}
    >
      Copyright © 2022. All rights reserved.
    </p>
  );
}

export default function Footer() {
  return (
    <Box
      style={{
        bottom: 0,
        margin: "0px !important",
      }}
      component="footer"
      sx={{
        py: 3,
        px: 2,
        mt: "auto",
        backgroundColor: "#313131",
      }}
    >
      <Container maxWidth="lg">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            marginBlockStart: "0",
            marginBlockEnd: "0",
          }}
        >
          <div
            style={{
              display: "flex",
              paddingBottom: "20px",
              flexWrap: "wrap",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <KeyboardArrowUpIcon />
              Back to Top
            </div>
          </div>
        </div>
      </Container>
    </Box>
  );
}
