// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* box-shadow: 5px 0px 0px 3px #DDDDDD; */
  overflow: hidden;
  border-bottom: 1px solid #dddddd;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: white;
  margin: 0px;
  padding: 0px;
}

.image {
  padding: 12px;
  height: 55px;
}

.list {
  display: flex;
  margin: 0px;
  padding: 0px;
}

@media (max-width: 885px) {
  .header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* box-shadow: 5px 0px 0px 3px #DDDDDD; */
    overflow: hidden;
    border-bottom: 1px solid #dddddd;
    margin: 0px;
    padding: 0px;
  }
  .image {
    padding: 12px;
    height: 40px;
  }
  .list {
    display: none;
    margin: 0px;
    padding: 0px;
  }
}

.list-item {
  padding: 0px 15px;
}

.link {
  line-height: 80px;
  text-decoration: none;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #313131;
}

.header a:hover {
  background-color: #ececec;
  color: #6eb48c;
}
`, "",{"version":3,"sources":["webpack://./src/Components/header.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,yCAAyC;EACzC,gBAAgB;EAChB,gCAAgC;EAChC,gBAAgB;EAChB,MAAM;EACN,YAAY;EACZ,uBAAuB;EACvB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,aAAa;EACb,WAAW;EACX,YAAY;AACd;;AAEA;EACE;IACE,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,yCAAyC;IACzC,gBAAgB;IAChB,gCAAgC;IAChC,WAAW;IACX,YAAY;EACd;EACA;IACE,aAAa;IACb,YAAY;EACd;EACA;IACE,aAAa;IACb,WAAW;IACX,YAAY;EACd;AACF;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,qBAAqB;EACrB,eAAe;EACf,kCAAkC;EAClC,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB","sourcesContent":[".header {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  /* box-shadow: 5px 0px 0px 3px #DDDDDD; */\n  overflow: hidden;\n  border-bottom: 1px solid #dddddd;\n  position: sticky;\n  top: 0;\n  z-index: 100;\n  background-color: white;\n  margin: 0px;\n  padding: 0px;\n}\n\n.image {\n  padding: 12px;\n  height: 55px;\n}\n\n.list {\n  display: flex;\n  margin: 0px;\n  padding: 0px;\n}\n\n@media (max-width: 885px) {\n  .header {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    /* box-shadow: 5px 0px 0px 3px #DDDDDD; */\n    overflow: hidden;\n    border-bottom: 1px solid #dddddd;\n    margin: 0px;\n    padding: 0px;\n  }\n  .image {\n    padding: 12px;\n    height: 40px;\n  }\n  .list {\n    display: none;\n    margin: 0px;\n    padding: 0px;\n  }\n}\n\n.list-item {\n  padding: 0px 15px;\n}\n\n.link {\n  line-height: 80px;\n  text-decoration: none;\n  font-size: 12px;\n  font-family: \"Poppins\", sans-serif;\n  font-weight: 400;\n  color: #313131;\n}\n\n.header a:hover {\n  background-color: #ececec;\n  color: #6eb48c;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
