import React from "react";
import './about.css'

function About() {
  return (
    <div className="about">
      <h1 style={{fontSize:"3.5rem"}}>EMail Signatur Generator</h1>
      <hr className="new1" />
      <p>
        Dieser EMail Signatur Generator wurde von der Firma <a href="https://www.maschinen-stockert.de">Maschinen Stockert GmbH - IT Abteilung</a> entwickelt.
      </p>
    </div>
  );
}

export default About;
