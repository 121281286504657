import FirstImage from "../assets/1.svg";
import SecondImage from "../assets/2.svg";
import ThirdImage from "../assets/3.svg";
import FourthImage from "../assets/4.svg";
import FifthImage from "../assets/5.svg";
import SixthImage from "../assets/6.svg";
import SeventhImage from "../assets/7.svg";
import EighthImage from "../assets/8.svg";

import "./Uses.css";
import React from "react";

const Uses = () => {
	return (
		<div>
			<section className="totally-free">

			</section>
		</div>
	);
};

export default Uses;