// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.addons {
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  font-size: 1em;
  display: flex;
  flex-direction: column;
  margin-top: 1em;
}

.addons-row {
  padding: 1em 1em 0em 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.addons-row select {
  width: 75%;
  margin-left: 1em;
}

.addons-row label {
  padding: 0.5em;
}

.addons-row textarea {
  margin: 1em 0em 0em 0.8em;
  padding: 0.7em;
  width: 100%;
  background-color: #eeeeee;
  outline: none;
  color: #1b2437;
  border: 1px solid #ddd;
  height: 120px;
  overflow-y: scroll;
  text-align: left;
  resize: none;
}

textarea:active {
  outline: none;
}

.app-badge {
  
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 1em 0em;
}

.app-badge input {
  border: 1px solid #dddddd;
  margin-left: 1em;
  padding: 0.3em;
  font-size: medium;
  border-radius: 5px;
  width: 100%;
}

.app-badge img {
  height: 2.5em
}

.color-checkbox {
  height: 20px;
  width: 20px;
  border: 2px solid #d7d7d7;
  cursor: pointer;
  border-radius: 5px;

}



`, "",{"version":3,"sources":["webpack://./src/Components/addons.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,kBAAkB;EAClB,cAAc;EACd,aAAa;EACb,sBAAsB;EACtB,eAAe;AACjB;;AAEA;EACE,wBAAwB;EACxB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,WAAW;EACX,yBAAyB;EACzB,aAAa;EACb,cAAc;EACd,sBAAsB;EACtB,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;;EAEE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,cAAc;EACd,iBAAiB;EACjB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE;AACF;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,yBAAyB;EACzB,eAAe;EACf,kBAAkB;;AAEpB","sourcesContent":[".addons {\n  width: 100%;\n  overflow-x: hidden;\n  overflow-y: hidden;\n  font-size: 1em;\n  display: flex;\n  flex-direction: column;\n  margin-top: 1em;\n}\n\n.addons-row {\n  padding: 1em 1em 0em 1em;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n\n.addons-row select {\n  width: 75%;\n  margin-left: 1em;\n}\n\n.addons-row label {\n  padding: 0.5em;\n}\n\n.addons-row textarea {\n  margin: 1em 0em 0em 0.8em;\n  padding: 0.7em;\n  width: 100%;\n  background-color: #eeeeee;\n  outline: none;\n  color: #1b2437;\n  border: 1px solid #ddd;\n  height: 120px;\n  overflow-y: scroll;\n  text-align: left;\n  resize: none;\n}\n\ntextarea:active {\n  outline: none;\n}\n\n.app-badge {\n  \n  display: flex;\n  flex-direction: row;\n  width: 100%;\n  margin: 1em 0em;\n}\n\n.app-badge input {\n  border: 1px solid #dddddd;\n  margin-left: 1em;\n  padding: 0.3em;\n  font-size: medium;\n  border-radius: 5px;\n  width: 100%;\n}\n\n.app-badge img {\n  height: 2.5em\n}\n\n.color-checkbox {\n  height: 20px;\n  width: 20px;\n  border: 2px solid #d7d7d7;\n  cursor: pointer;\n  border-radius: 5px;\n\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
