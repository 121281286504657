// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style-input {
  display: flex;
  flex-direction: column;
  padding: 1em;
}

.style {
  width: 100%;
  overflow-x: hidden;
}

.style-input > label {
  padding: 0.5em;
  background-color: white;
  text-transform: uppercase;
  color: black;
  border: 1px solid #dddddd;
  border-radius: 2px 2px 0px 0px;
}


input:focus {
  outline: none;
}

select:focus {
    outline: none;
  }

.color-input {
  display: flex;
  flex-direction: row;
}

.color-input > input {
  background-color: #f7f7f7;
  color: black;
  border: 1px solid #dddddd;
  border-radius: 0px 0px 2px 2px;
  font-size: medium;
}

.select-input {
  background-color: #f7f7f7;
  color: black;
  border: 1px solid #dddddd;
  border-radius: 0px 0px 2px 2px;
  font-size: medium;
  padding: 0.5em;
}
`, "",{"version":3,"sources":["webpack://./src/Components/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,uBAAuB;EACvB,yBAAyB;EACzB,YAAY;EACZ,yBAAyB;EACzB,8BAA8B;AAChC;;;AAGA;EACE,aAAa;AACf;;AAEA;IACI,aAAa;EACf;;AAEF;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,yBAAyB;EACzB,8BAA8B;EAC9B,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,yBAAyB;EACzB,8BAA8B;EAC9B,iBAAiB;EACjB,cAAc;AAChB","sourcesContent":[".style-input {\n  display: flex;\n  flex-direction: column;\n  padding: 1em;\n}\n\n.style {\n  width: 100%;\n  overflow-x: hidden;\n}\n\n.style-input > label {\n  padding: 0.5em;\n  background-color: white;\n  text-transform: uppercase;\n  color: black;\n  border: 1px solid #dddddd;\n  border-radius: 2px 2px 0px 0px;\n}\n\n\ninput:focus {\n  outline: none;\n}\n\nselect:focus {\n    outline: none;\n  }\n\n.color-input {\n  display: flex;\n  flex-direction: row;\n}\n\n.color-input > input {\n  background-color: #f7f7f7;\n  color: black;\n  border: 1px solid #dddddd;\n  border-radius: 0px 0px 2px 2px;\n  font-size: medium;\n}\n\n.select-input {\n  background-color: #f7f7f7;\n  color: black;\n  border: 1px solid #dddddd;\n  border-radius: 0px 0px 2px 2px;\n  font-size: medium;\n  padding: 0.5em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
