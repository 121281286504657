export const CHANGE_MAIN_VALUE = "CHANGE_MAIN_VALUE"
export const ADD_FIELD = "ADD_FIELD"
export const REMOVE_FIELD = "REMOVE_FIELD"

export const ADD_SOCIAL = "ADD_SOCIAL"
export const REMOVE_SOCIAL = "REMOVE_SOCIAL"
export const UPDATE_SOCIAL = "UPDATE_SOCIAL"

export const CHANGE_STYLE_VALUE = "CHANGE_STYLE_VALUE"

export const CHANGE_CTA_VALUE = "CHANGE_CTA_VALUE"

export const CHANGE_ADDONS_VALUE = "CHANGE_ADDONS_VALUE"
