// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  padding: 0px;
  list-style-type: none;
  text-decoration: none;
}

.footerMenu a {
  padding: 1rem;
  color: #999;
}

@media (max-width: 700px) {
  .footerMenu a {
    padding: 5px;
  }
}

.footerMenu a :hover {
  color: #6eb48c;
}

.topButton {
  margin-top: 20px;
  margin-bottom: 10px;
  background: transparent;
  color: #999;
  border: none;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.4s ease;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.topButton :hover {
  color: #fff !important;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Footer.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,qBAAqB;EACrB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,WAAW;AACb;;AAEA;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,yBAAyB;;EAEzB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,sBAAsB;EACtB,eAAe;AACjB","sourcesContent":["* {\n  padding: 0px;\n  list-style-type: none;\n  text-decoration: none;\n}\n\n.footerMenu a {\n  padding: 1rem;\n  color: #999;\n}\n\n@media (max-width: 700px) {\n  .footerMenu a {\n    padding: 5px;\n  }\n}\n\n.footerMenu a :hover {\n  color: #6eb48c;\n}\n\n.topButton {\n  margin-top: 20px;\n  margin-bottom: 10px;\n  background: transparent;\n  color: #999;\n  border: none;\n  font-size: 16px;\n  font-weight: 600;\n  transition: all 0.4s ease;\n\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n.topButton :hover {\n  color: #fff !important;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
