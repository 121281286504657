import { CHANGE_ADDONS_VALUE } from "../actions/actionType";


const initialState = {
    form: {
        type: "",
        data: {
            confidentiality: {
                editable: false,
                content: "WICHTIG: Der Inhalt dieser E-Mail und aller Anhänge ist vertraulich. Es ist strengstens untersagt, irgendeinen Teil dieser Nachricht ohne schriftliche Zustimmung des Absenders mit Dritten zu teilen. Falls Sie diese Nachricht irrtümlicherweise erhalten haben, antworten Sie bitte auf diese Nachricht und folgen Sie mit ihrer Löschung, damit wir sicherstellen können, dass ein solcher Fehler in Zukunft nicht mehr auftritt."
            },
            environmental: {
                editable: false,
                content: "Bitte drucken Sie diese E-Mail nur aus, wenn es unbedingt notwendig ist. Jede nicht ausgedruckte E-Mail trägt zum Schutz der Umwelt bei."
            },
            custom: {
                editable: true,
                content: ""
            }
        },
        fontSize: 12,
        width: 450,
        selected: "confidentiality",
        amazonBadge: "",
        appleBadge: "",
        ebayBadge: "",
        googleBusinessBadge: "",
        googlePlayBadge: "",
        badgeShape: 1,
        badgeHeight: 50,
        badgePaddingTop: 15,
        badgeColor: 1
    }
}

const addonsReducer = (prevState = initialState, action) => {
    switch(action.type){
        case CHANGE_ADDONS_VALUE:
            let stringifiedPrevState = JSON.stringify(prevState);
            let newState = JSON.parse(stringifiedPrevState);

            if(action.key === "custom"){
                newState["form"]["data"]["custom"]["content"] = action.value
                newState["form"]["selected"] = "custom"
            }
            else if(action.key === "reset"){
                newState = initialState
            }
            else if(action.key === "selected"){
                if(action.value !== "custom" && newState["form"]["selected"] === "custom")
                    newState["form"]["data"]["custom"]["content"] = ""

                newState["form"]["selected"] = action.value
            }
            else newState["form"][action.key] = action.value;
            return {
                ...newState
            }
        
        default: return prevState
    }
}

export default addonsReducer