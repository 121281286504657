// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
	font-family: "Poppins" !important;
	overflow: scroll;
	scroll-behavior: smooth;
	overflow-x: hidden;
}

input {
	font-family: "Poppins";
}

label {
	font-family: "Poppins";
}

p {
	font-family: "Poppins";
}

h1,
h2,
h3,
h4 {
	font-family: "Recursive", sans-serif;
}

span,
select,
option {
	font-family: "Poppins";
}

.breadcrumb-main {
	margin-top: 1rem;
	margin-left: 1rem;
}

@media (max-width: 500px) {
	.breadcrumb-link {
		font-size: 13px;
	}
}

@media (max-width: 370px) {
	.breadcrumb-link {
		font-size: 12px;
	}
}

@media (max-width: 350px) {
	.breadcrumb-link {
		font-size: 11px;
	}
}

@media (max-width: 325px) {
	.breadcrumb-link {
		font-size: 10px;
	}
}
`, "",{"version":3,"sources":["webpack://./src/Components/App.css"],"names":[],"mappings":"AAAA;CACC,iCAAiC;CACjC,gBAAgB;CAChB,uBAAuB;CACvB,kBAAkB;AACnB;;AAEA;CACC,sBAAsB;AACvB;;AAEA;CACC,sBAAsB;AACvB;;AAEA;CACC,sBAAsB;AACvB;;AAEA;;;;CAIC,oCAAoC;AACrC;;AAEA;;;CAGC,sBAAsB;AACvB;;AAEA;CACC,gBAAgB;CAChB,iBAAiB;AAClB;;AAEA;CACC;EACC,eAAe;CAChB;AACD;;AAEA;CACC;EACC,eAAe;CAChB;AACD;;AAEA;CACC;EACC,eAAe;CAChB;AACD;;AAEA;CACC;EACC,eAAe;CAChB;AACD","sourcesContent":["body {\n\tfont-family: \"Poppins\" !important;\n\toverflow: scroll;\n\tscroll-behavior: smooth;\n\toverflow-x: hidden;\n}\n\ninput {\n\tfont-family: \"Poppins\";\n}\n\nlabel {\n\tfont-family: \"Poppins\";\n}\n\np {\n\tfont-family: \"Poppins\";\n}\n\nh1,\nh2,\nh3,\nh4 {\n\tfont-family: \"Recursive\", sans-serif;\n}\n\nspan,\nselect,\noption {\n\tfont-family: \"Poppins\";\n}\n\n.breadcrumb-main {\n\tmargin-top: 1rem;\n\tmargin-left: 1rem;\n}\n\n@media (max-width: 500px) {\n\t.breadcrumb-link {\n\t\tfont-size: 13px;\n\t}\n}\n\n@media (max-width: 370px) {\n\t.breadcrumb-link {\n\t\tfont-size: 12px;\n\t}\n}\n\n@media (max-width: 350px) {\n\t.breadcrumb-link {\n\t\tfont-size: 11px;\n\t}\n}\n\n@media (max-width: 325px) {\n\t.breadcrumb-link {\n\t\tfont-size: 10px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
