import BookmarkIcon from "../assets/bookmark.svg";

import { FaFacebookSquare, FaTwitterSquare, FaLinkedin } from "react-icons/fa";
import "./Share.css";

const fbRedirect = () => {
  window.open(
    "https://www.facebook.com/sharer/sharer.php?u=#",
    "_blank"
  );
};

const twitterRedirect = () => {
  window.open(
    "https://twitter.com/intent/tweet?url=#",
    "_black"
  );
};

const linkedinRedirect = () => {
  window.open(
    "https://www.linkedin.com/sharing/share-offsite/?url=#",
    "_blank"
  );
};

const Share = () => {
  return (
    <div className="share-container">

    </div>
  );
};

export default Share;
