import "./Content.css";

const Content = () => {
  return (
    <div className="content">
    <div className="content__segment">
	
	</div>
    </div>
  );
};

export default Content;
