// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.social-input {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 1em 0em;
}

.social-input > input {
  border: 1px solid #dddddd;
  margin-left: 1em;
  padding: 0.5em;
  font-size: inherit;
  border-radius: 5px;
}

.social-icons {
  border: 1px solid #dddddd;
  border-radius: 0px;
  align-content: center;
  text-align: center;
  padding: 1em 4em;
  display: flex;
  flex-direction: column;
}

.social-icons > span {
  border-bottom: 1px solid #dddddd;
  padding-bottom: 0.8em;
  width: 100%;
}

input:focus {
  outline: none;
}

.social {
  padding: 1em;
  display: flex;
  flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/Components/social.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,cAAc;EACd,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,qBAAqB;EACrB,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,gCAAgC;EAChC,qBAAqB;EACrB,WAAW;AACb;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB","sourcesContent":[".social-input {\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n  margin: 1em 0em;\n}\n\n.social-input > input {\n  border: 1px solid #dddddd;\n  margin-left: 1em;\n  padding: 0.5em;\n  font-size: inherit;\n  border-radius: 5px;\n}\n\n.social-icons {\n  border: 1px solid #dddddd;\n  border-radius: 0px;\n  align-content: center;\n  text-align: center;\n  padding: 1em 4em;\n  display: flex;\n  flex-direction: column;\n}\n\n.social-icons > span {\n  border-bottom: 1px solid #dddddd;\n  padding-bottom: 0.8em;\n  width: 100%;\n}\n\ninput:focus {\n  outline: none;\n}\n\n.social {\n  padding: 1em;\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
