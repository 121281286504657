// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about {
  text-align: center;
  margin: auto;
  padding: 2vw;
}

.about h1 {
  margin-top: 0px;
  font-family: "Recursive";
  color: #0385db;
  margin-bottom: 0px !important;
}

hr.new1 {
  border: 1px solid #349c27;
  width: 90px;
  margin-bottom: 1rem;
}

.about > p {
  margin: 0 10%;
  font-family: "Poppins";
}

@media only screen and (max-width: 1025px) {
  .about {
    text-align: center;
    margin: auto;
    padding: 1em;
  }

  .about h1 {
    font-family: "Recursive" !important;
    color: #0385db;
  }

  .p {
    font-family: "Poppins", sans-serif;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/about.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,eAAe;EACf,wBAAwB;EACxB,cAAc;EACd,6BAA6B;AAC/B;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE;IACE,kBAAkB;IAClB,YAAY;IACZ,YAAY;EACd;;EAEA;IACE,mCAAmC;IACnC,cAAc;EAChB;;EAEA;IACE,kCAAkC;EACpC;AACF","sourcesContent":[".about {\n  text-align: center;\n  margin: auto;\n  padding: 2vw;\n}\n\n.about h1 {\n  margin-top: 0px;\n  font-family: \"Recursive\";\n  color: #0385db;\n  margin-bottom: 0px !important;\n}\n\nhr.new1 {\n  border: 1px solid #349c27;\n  width: 90px;\n  margin-bottom: 1rem;\n}\n\n.about > p {\n  margin: 0 10%;\n  font-family: \"Poppins\";\n}\n\n@media only screen and (max-width: 1025px) {\n  .about {\n    text-align: center;\n    margin: auto;\n    padding: 1em;\n  }\n\n  .about h1 {\n    font-family: \"Recursive\" !important;\n    color: #0385db;\n  }\n\n  .p {\n    font-family: \"Poppins\", sans-serif;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
