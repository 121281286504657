// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form {
  /* border: 2px solid black; */
  width: 80%;
  justify-content: center;
  /* align-items: center; */
  display: flex;
  flex-direction: row;
  background-color: #fff;
  justify-content: space-between;
  font-size: small;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  transition: .3s
  /* margin: auto; */
}


@media only screen and (max-width: 1025px) {
  .form {
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: none;
    transition: none;
    width: 99%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/form.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,UAAU;EACV,uBAAuB;EACvB,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,8BAA8B;EAC9B,gBAAgB;EAChB,wCAAwC;EACxC,cAAc;EACd,kBAAkB;AACpB;;;AAGA;EACE;IACE,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,sBAAsB;IACtB,gBAAgB;IAChB,gBAAgB;IAChB,UAAU;EACZ;AACF","sourcesContent":[".form {\n  /* border: 2px solid black; */\n  width: 80%;\n  justify-content: center;\n  /* align-items: center; */\n  display: flex;\n  flex-direction: row;\n  background-color: #fff;\n  justify-content: space-between;\n  font-size: small;\n  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);\n  transition: .3s\n  /* margin: auto; */\n}\n\n\n@media only screen and (max-width: 1025px) {\n  .form {\n    margin-bottom: 0;\n    display: flex;\n    flex-direction: column;\n    background-color: #fff;\n    box-shadow: none;\n    transition: none;\n    width: 99%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
